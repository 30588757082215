<template>
  <div class="flex items-center justify-center w-full mb-4">
    <div class="flex flex-wrap w-full index_box">
      <div class="cursor-pointer outer" v-for="(item, index) in menu" :key="index"
        @click="goPage(item.type, item.path)">
        <div class="relative duration-500 bg-white inner">
          <div class="absolute content_box">
            <div class="flex items-center justify-center">
              <img :src="item.icon" alt />
              <!-- <img src="../../../../assets/images/serve/drive.png" alt=""> -->
            </div>
            <div class="content_title">{{ item.title }}</div>
            <div class="text-gray-500 conent_desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      menu: [
        {
          title: "驱动下载",
          desc: "为您提供火影系列产品的ROM下载",
          icon: require("../../../../assets/images/serve/drive.png"),
          path: "/index/serve/drive",
          type: "inner",
        },
        {
          title: "品牌活动",
          desc: "为您提供火影系列产品热销款",
          icon: require("../../../../assets/images/serve/cart.png"),
          path: "/index/resources",
          type: "inner",
        },

        {
          title: "真伪查验",
          desc: "为您提供火影系列产品的查询服务",
          icon: require("../../../../assets/images/serve/warranty.png"),
          path: "/index/inspection",
          type: "inner",
        },
        // { title: "保修查询", desc: "为您提供火影系列产品的保修服务", icon: require('../../../../assets/images/serve/warranty.png'), path: "/index/warranty", type: "inner" },
        {
          title: "壁纸中心",
          desc: "为您提供火影系列产品的壁纸下载",
          icon: require("../../../../assets/images/serve/wallpaper.png"),
          path: "/index/serve/wallpaper",
          type: "inner",
        },
        {
          title: "服务门店",
          desc: "产品售后服务地址查询给您提供更专业的服务",
          icon: require("../../../../assets/images/serve/stores.png"),
          path: "/index/stores",
          type: "inner",
        },
        {
          title: "问题咨询",
          desc: "为您解答在购机、用机及售后服务方便的问题",
          icon: require("../../../../assets/images/serve/problem.png"),
          path: "/index/sales/chat",
          type: "outer",
        },
      ],
    };
  },
  components: {},
  computed: {
    ...mapState(["logoPic"]),
  },
  methods: {
    goPage(type, path) {
      this.$router.push({ path })
      // if (type == "inner") {
      //   this.$router.push({ path });
      // } else {
      //   window.open(this.logoPic.customer);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.index_box {
  max-width: var(--max-width);
  padding-left: var(--margin-base);
  padding-right: var(--margin-base);
}

.outer {
  width: 33.33%;
  height: 0;
  padding-bottom: 33.33%;

  // background: red;
  .inner {
    margin: 2%;
    width: 96%;
    height: 0;
    padding-bottom: 96%;

    .content_box {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media screen and (max-width: 640px) {
  .outer {
    margin-bottom: 0.125rem;
  }

  .content_box {
    width: 116%;

    img {
      width: 25%;
    }

    .content_title {
      font-size: 0.8125rem;
      margin-top: 0.375rem;
    }

    .conent_desc {
      font-size: 0.75rem;
      transform: scale(0.8);
    }
  }
}

@media screen and (min-width: 640px) {
  .outer {
    margin-bottom: 0.375rem;
  }

  .inner:hover {
    box-shadow: 0px 0px 0.8125rem 0px rgba(0, 0, 0, 0.1),
      0px 0px 0.5625rem 0px rgba(0, 0, 0, 0.05);
  }

  .content_box {
    width: 95%;

    img {
      width: 30%;
    }

    .content_title {
      font-size: 1rem;
      margin-top: 1.25rem;
      margin-bottom: 0.625rem;
    }

    .conent_desc {
      font-size: 0.8125rem;
    }
  }
}
</style>
